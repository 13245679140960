<template>
  <main class="streams-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <router-link
            to="/streams"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
          >
            <i class="i-grid float-left"></i>
            <span>{{ $t("open_list") }}</span>
          </router-link>
          <a
            href="/categories/streams"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
          >
            <i class="i-folder float-left"></i>
            <span>{{ $t("categories") }}</span>
          </a>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5 row">
        <div
          v-if="$route.query.ruCategory || $route.query.enCategory"
          class="w-100 text-center"
        >
          {{ $t("category_fill_text") }}
          {{
            $store.state.lang == "ru"
              ? $route.query.ruCategory
              : $route.query.enCategory
          }}:
        </div>
        <template v-else>
          <div
            class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
            v-for="stream in streams"
            :key="stream.stream_id"
          >
            <stream-card :data="stream" />
          </div>
          <div
            class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
            v-for="stream in inactive_streams"
            :key="stream.stream_id"
          >
            <stream-card :data="stream" />
          </div>
        </template>
      </div>
    </section>
  </main>
</template>

<script>
import StreamCard from "./components/stream-card.vue";
export default {
  name: "Streams",
  components: {
    StreamCard
  },
  computed: {
    reverse_streams() {
      return this.streams.reverse();
    }
  },
  data() {
    return {
      stream_ids: [],
      streams: [],
      inactive_streams: []
      // streams: [
      //   {
      //     user: {
      //       name: "Testovich",
      //       photo: "https://images.unsplash.com/photo-1607292976966-c2a9aa8cbc28?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80",
      //       id: 120
      //     },
      //     preview: "https://images.unsplash.com/photo-1601758260944-72f34e1b8d57?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
      //     description: "description"
      //   }
      // ]
    };
  },
  methods: {
    async initStream(data) {},

    addComment(data) {
      const stream = this.streams.find(element => {
        return element.stream_id == data.stream_id;
      });

      stream.comment_count += 1;
    },

    addUser(data) {
      const stream = this.streams.find(element => {
        return element.stream_id == data.stream_id;
      });

      stream.viewer_count += 1;
    },

    deleteUser(data) {
      const stream = this.streams.find(element => {
        return element.stream_id == data.stream_id;
      });

      stream.viewer_count -= 1;
    },

    addLike(data) {
      const stream = this.streams.find(element => {
        return element.stream_id == data.stream_id;
      });

      stream.like_count = data.count;
    },

    async initStreamCounters(data) {
      if (
        this.streams.some(stream => {
          stream.hasOwnProperty(data.stream_id);
        })
      )
        return;

      // await new Promise(resolve => {
      //   setTimeout(() => resolve(), 5000)
      // })

      const stream_res = await this.$api.v2base.get(
        `/streams/${data.stream_id}`
      );
      console.log("Stream: ", stream_res);

      data.stream_object = stream_res.data;

      data.live = true;

      console.log(data);

      this.streams.push(data);

      this.streams.sort((a, b) => {
        return (
          new Date(b.stream_object.created_at) -
          new Date(a.stream_object.created_at)
        );
      });
      console.log(this.streams);
    },

    endStream(data) {
      console.log(data);
      const stream = this.streams.find(element => {
        return element.stream_id == data.stream_id;
      });

      console.log(stream);

      stream.live = false;

      const index = this.streams.indexOf(stream);
      this.streams.splice(index, 1);
      this.inactive_streams.push(stream);
    },

    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        stream_started: this.initStream,
        first: this.initStreamCounters,
        stream_ended: this.endStream,
        new_comment: this.addComment,
        user_joined: this.addUser,
        user_left: this.deleteUser,
        new_like: this.addLike
      };

      const { type, ...data } = JSON.parse(response.data);

      console.log(type, data);

      if (!handlers[type]) return false;

      setTimeout(() => handlers[type](data));
    },

    initSocket() {
      return new Promise(resolve => {
        const token = this.$store.state.token;
        const socket = new WebSocket(
          `wss://arapi.gora.studio/v2/streams/admin/tracker?token=${token}`
        );

        socket.addEventListener("open", () => {
          this.connection = socket;
          resolve();
        });

        socket.addEventListener("close", () => {
          console.log("RECONNECT");
          // this.initSocket();
        });
        socket.addEventListener("message", this.handleResponse);
      });
    }
  },
  beforeDestroy() {
    this.connection.close();
  },
  async mounted() {
    console.log(this.$store);
    this.$navbar.name = this.$t("streams");

    await this.initSocket();

    setTimeout(() => {
      this.streams.sort((a, b) => {
        // console.log(b.stream_object.created_at);
        return (
          new Date(b.stream_object.created_at) -
          new Date(a.stream_object.created_at)
        );
      });
    }, 1000);
  }
};
</script>

<style lang="sass" scoped>
.router-link-exact-active
  background: #028cff
  color: rgba(255, 255, 255, 0.9)
</style>
