var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "streams-page" }, [
    _c(
      "section",
      {
        staticClass: "border-bottom bg-lighter md-up:sticky-top",
        staticStyle: { top: "70px" }
      },
      [
        _c("div", { staticClass: "container py-3" }, [
          _c(
            "nav",
            { staticClass: "nav sm-down:flex-column" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:d-none",
                  attrs: { to: "/streams" }
                },
                [
                  _c("i", { staticClass: "i-grid float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("open_list")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:d-none",
                  attrs: { href: "/categories/streams" }
                },
                [
                  _c("i", { staticClass: "i-folder float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("categories")))])
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("section", [
      _c(
        "div",
        { staticClass: "container py-5 row" },
        [
          _vm.$route.query.ruCategory || _vm.$route.query.enCategory
            ? _c("div", { staticClass: "w-100 text-center" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("category_fill_text")) +
                    "\n        " +
                    _vm._s(
                      _vm.$store.state.lang == "ru"
                        ? _vm.$route.query.ruCategory
                        : _vm.$route.query.enCategory
                    ) +
                    ":\n      "
                )
              ])
            : [
                _vm._l(_vm.streams, function(stream) {
                  return _c(
                    "div",
                    {
                      key: stream.stream_id,
                      staticClass:
                        "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                    },
                    [_c("stream-card", { attrs: { data: stream } })],
                    1
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.inactive_streams, function(stream) {
                  return _c(
                    "div",
                    {
                      key: stream.stream_id,
                      staticClass:
                        "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                    },
                    [_c("stream-card", { attrs: { data: stream } })],
                    1
                  )
                })
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }